<script>
  import TTRFixdSensorImage from '@/images/landing-page/scorpio/ttr-fixd-sensor.png'
  import { Checkmark, FaStarFilledRound } from '@/components/icons'
  export let checkoutLink
</script>

<style>
  .box-shadow {
    box-shadow: 0 2px 10px 1px #879299;
  }
</style>

<div class="absolute right-[-16px] bg-white top-4">
  <div class="fixed max-w-[271px] py-8 px-5 rounded-xl box-shadow bg-white">
    <h4 class="text-xl text-center">
      FIXD Makes Car Care Easy for <span class="italic">Any</span> Driver
    </h4>

    <div class="mx-auto w-fit mt-2">
      <div class="flex items-center">
        <div
          class="border-2 border-green rounded-full size-6 flex items-center justify-center mr-2"
        >
          <Checkmark color="green" size="md" />
        </div>
        <p class="text-xl font-medium">Save Money</p>
      </div>
      <div class="flex items-center">
        <div
          class="border-2 border-green rounded-full size-6 flex items-center justify-center mr-2"
        >
          <Checkmark color="green" size="md" />
        </div>
        <p class="text-xl font-medium">Save time</p>
      </div>
      <div class="flex items-center">
        <div
          class="border-2 border-green rounded-full size-6 flex items-center justify-center mr-2"
        >
          <Checkmark color="green" size="md" />
        </div>
        <p class="text-xl font-medium">Avoid Stress</p>
      </div>
    </div>

    <div class="text-center my-4">
      <img src={TTRFixdSensorImage} height="200" width="150" alt="sensor" />
    </div>

    <div class="mb-6">
      <a
        href={checkoutLink}
        class="text-xl py-3 no-underline font-bold text-white bg-[#0d232d] w-full block text-center hover:cursor-pointer"
        >Get up to 67% off</a
      >
    </div>

    <div class="mb-4">
      <div class="bg-grey-200 py-1 mb-3">
        <p class="text-center font-bold text-sm">Customer Ratings</p>
      </div>

      <div class="leading-5 text-center">
        <div class="flex items-center gap-1">
          <div class="inline-flex space-x-[1px]">
            {#each new Array(5) as _}
              <FaStarFilledRound color="yellow" size="sm" />
            {/each}
          </div>
          <span class="text-sm font-medium">4.8 out of 5 stars</span>
        </div>
        <p class="text-sm font-medium">4,000+ user ratings</p>
      </div>
    </div>

    <div class="px-1 space-y-2">
      <div class="flex items-center w-full">
        <p class="text-sm font-medium w-11">5 Star</p>

        <div class="relative bg-grey-200 rounded w-[140px] h-4">
          <div class="absolute left-0 w-[85%] h-4 bg-yellow rounded" />
        </div>
        <p class="text-sm font-medium text-right w-9">85%</p>
      </div>

      <div class="flex items-center w-full">
        <p class="text-sm font-medium w-11">4 Star</p>

        <div class="relative bg-grey-200 rounded w-[140px] h-4">
          <div class="absolute left-0 w-[12%] h-4 bg-yellow rounded" />
        </div>
        <p class="text-sm font-medium text-right w-9">10%</p>
      </div>

      <div class="flex items-center w-full">
        <p class="text-sm font-medium w-11">3 Star</p>

        <div class="relative bg-grey-200 rounded w-[140px] h-4">
          <div class="absolute left-0 w-[5%] h-4 bg-yellow rounded" />
        </div>
        <p class="text-sm font-medium text-right w-9">2%</p>
      </div>

      <div class="flex items-center w-full">
        <p class="text-sm font-medium w-11">2 Star</p>

        <div class="relative bg-grey-200 rounded w-[140px] h-4">
          <div class="absolute left-0 w-[5%] h-4 bg-yellow rounded" />
        </div>
        <p class="text-sm font-medium text-right w-9">2%</p>
      </div>

      <div class="flex items-center w-full">
        <p class="text-sm font-medium w-11">1 Star</p>

        <div class="relative bg-grey-200 rounded w-[140px] h-4">
          <div class="absolute left-0 w-[3%] h-4 bg-yellow rounded" />
        </div>
        <p class="text-sm font-medium text-right w-9">1%</p>
      </div>
    </div>
  </div>
</div>
